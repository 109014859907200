import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useAppContext } from 'view/contexts/appContext/appContext'
import './whatsappButton.css'

type TProps = {
  className?: string
  text?: string
}

const WhatsappButton: React.FC<TProps> = ({ className, text }) => {
  const { organizationInfo } = useAppContext()
  const whatsAppNumber = organizationInfo?.whatsapp
    ?.replace('(', '')
    .replace(')', '')
    .replace('-', '')
    .replace(' ', '')

  return (
    <div className={`whatsapp-button ${className || ''}`}>
      <a
        href={`https://api.whatsapp.com/send?phone=55${whatsAppNumber}`}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={['fab', 'whatsapp']} />
        <span>{text || 'Fale conosco'}</span>
      </a>
    </div>
  )
}

export default WhatsappButton
