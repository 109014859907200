import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useAppContext } from 'view/contexts/appContext/appContext'
import './whatsappButton.css'

const WhatsappButtonMobile: React.FC = () => {
  const { organizationInfo } = useAppContext()
  const whatsAppNumber = organizationInfo?.whatsapp
    ?.replace('(', '')
    .replace(')', '')
    .replace('-', '')
    .replace(' ', '')

  return (
    <a
      href={`https://api.whatsapp.com/send?phone=55${whatsAppNumber}`}
      target="_blank"
      className="whatsapplink top-bar-nav-item"
      rel="noreferrer"
    >
      <FontAwesomeIcon icon={['fab', 'whatsapp']} />
    </a>
  )
}

export default WhatsappButtonMobile
