import { hollydays } from 'aplication/localData/hollydays/hollydays'
import {
  dateIsBefore,
  dateToAmericanDate,
  diffHoursBetweenDates,
  getFullTime,
  isToday,
  mergeDateDateAndTimeDate,
  newDateByTimeString,
  timeIsBefore
} from 'aplication/utils/app/dateTimeCare/dateTimeCare'

import { TLocation, TLocationRule } from 'domain/entities/TLocation'
import { TBookingDateBlock } from '../bookingDateBlocks/TBookingDateBlock'

/**
 * verifica se a "date" está a X horas ("requiredDiffHours") a frente de "today"
 */
export function inRequiredDiffBetweanDays(
  requiredDiffHours: number,
  date: Date
): boolean {
  if (!requiredDiffHours) return true
  const today = new Date()
  let diff = diffHoursBetweenDates(today, date)
  if (!dateIsBefore(today, date)) {
    diff = Math.abs(diff) * -1
  }

  return requiredDiffHours <= diff
}

function selectDay(dayNumber: number): string {
  const day = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab']
  return day[dayNumber]
}

function isAHollyday(date: Date): boolean {
  const months = [
    'JAN',
    'FEV',
    'MAR',
    'ABR',
    'MAI',
    'JUN',
    'JUL',
    'AGO',
    'SET',
    'OUT',
    'NOV',
    'DEZ'
  ]

  const month = date.getMonth()
  const dayDate = date.getDate()
  const stringDate = `${dayDate}-${months[month]}`

  return hollydays.values.includes(stringDate)
}

function getRuleById(id: string, rules: TLocation[]): TLocation {
  return rules.find(r => `${r.id}` === id) || ({} as TLocation)
}

function getTimeRule(
  selectedDate: Date,
  rule: TLocation
): TLocationRule | null {
  const day = selectDay(selectedDate.getDay())
  let timeRule = null

  if (!rule) return null
  if (Object.keys(rule).includes(day)) {
    timeRule = rule[day]
  } else if (rule.hollydays && isAHollyday(selectedDate)) {
    timeRule = rule.hollydays
  } else {
    timeRule = rule.default
  }
  if (
    !timeRule ||
    typeof timeRule === 'string' ||
    typeof timeRule === 'number'
  ) {
    return null
  }
  return timeRule
}

/**
 * verifica se o parametro "date" é hoje e se, até o final do dia("date")
 * ele contem o total de horas requeridas ("timeRequired")
 */
function dateIsTodayAndHasThisTime(date: Date, timeRequired: number): boolean {
  if (!isToday(date)) return true
  const now = new Date()
  const endOfDay = new Date(`${dateToAmericanDate(now)} 23:59:59`)

  const timeDiff = diffHoursBetweenDates(now, endOfDay)
  return timeRequired <= timeDiff
}

/**
 * Verifica se já passou o horário de fechamento ou
 * se restam 30 minutos para fechar
 */
function dayMaxHourIsPast(date: Date, rule: any): boolean {
  if (!isToday(date)) return false
  const day = selectDay(date.getDay())
  const currentDate = new Date()
  const currentHour = currentDate.getHours()
  const currentMinuts = currentDate.getMinutes()

  let timeRule: any = rule[day] || rule.default

  if (isAHollyday(date)) timeRule = rule.hollydays

  if (!timeRule) return false

  const maxHour = timeRule?.maxHour.split(':')

  return (
    currentHour > parseInt(maxHour[0]) ||
    (currentHour === parseInt(maxHour[0]) && currentMinuts <= 30)
  )
}

function makeTimeList(): Date[] {
  const timeList: Date[] = []
  for (let i = 0; i <= 23; i++) {
    const hour = i < 10 ? `0${i}` : i
    timeList.push(new Date(`2023-06-03 ${hour}:00:00`))
  }
  return timeList
}

function isPastTime(timeDate: Date, selectedDate: Date): boolean {
  if (!isToday(selectedDate)) return false
  const currentDate = new Date()
  const currentHour = currentDate.getHours()
  const currentMinuts = currentDate.getMinutes()
  const timeHour = timeDate.getHours()
  return (
    currentHour > timeHour ||
    (currentHour === timeHour && currentMinuts >= 0) ||
    (currentHour + 1 === timeHour && currentMinuts > 30)
  )
}

/**
 * Retorna os horários que não serão bloqueados na data "blockDateTime"
 */
export function checkBookingDateBlockPeriodTime(
  blockDateTime: Date, // start || final
  timeRule: TLocationRule, // minHour, maxHour
  closePeriod?: boolean // define que é um horário de fechamento do período
): Date[] {
  const timeList = makeTimeList()

  return timeList.filter(time => {
    const openTime = newDateByTimeString(timeRule.minHour)
    const closeTime = newDateByTimeString(timeRule.maxHour)

    // horário de funcionamento
    const openWindow =
      timeIsBefore(openTime, time) && timeIsBefore(time, closeTime)
    // horários fora do bloqueio
    let blockWindow: boolean
    if (closePeriod) {
      blockWindow = timeIsBefore(blockDateTime, time)
    } else {
      blockWindow = timeIsBefore(time, blockDateTime)
    }

    return openWindow && blockWindow
  })
}

export function bookingRulesValidatior(rules: TLocation[]) {
  /**
   * Verifica se possui horários vagos na data
   */
  function dayTimeValidation(date: Date, ruleId: string): boolean {
    const rule = getRuleById(ruleId, rules)

    const requiredDifTime = rule.tempoAntecedencia

    const timeList = makeTimeList()

    const timesValidation = timeList.map(time => {
      const timeRule = getTimeRule(date, rule)
      if (timeRule) {
        const openTime = newDateByTimeString(timeRule.minHour)
        const closeTime = newDateByTimeString(timeRule.maxHour)
        if (!timeIsBefore(openTime, time) || !timeIsBefore(time, closeTime)) {
          return false
        }
      }

      const dateTimeMerged = mergeDateDateAndTimeDate(date, time)
      return inRequiredDiffBetweanDays(requiredDifTime, dateTimeMerged)
    })
    return !timesValidation.every(value => !value)
  }

  /**
   * desativa a data caso ela retorne da regra como nula
   * ou se não passa nas regras de verificação do horário
   */
  function dayRuleIsNull(date: Date, ruleName: string): boolean {
    const rule = getRuleById(ruleName, rules)
    if (!rule) return false

    const requiredDifTime = rule?.tempoAntecedencia
    if (requiredDifTime && !dateIsTodayAndHasThisTime(date, requiredDifTime)) {
      return false
    }

    if (isAHollyday(date) && rule.hollydays === null) return false

    const day = selectDay(date.getDay())
    if (rule[day] !== null && !dayMaxHourIsPast(date, rule)) {
      return true
    }
    return false
  }

  /**
   * Aplica bloqueio por BookingDateBlockPeriod
   * Bloqueio por período
   */
  function dateFilterInBookingDateBlockPeriod(
    date: Date,
    location: string,
    bookingDateBlocks: TBookingDateBlock[]
  ): boolean {
    const inPeriod: boolean[] = bookingDateBlocks.map(dateBlock => {
      // verifica localização
      const locationNumber = parseInt(location)
      if (!dateBlock.locationId.includes(locationNumber)) return true
      // verifica data
      const start = new Date(dateBlock.start)
      const final = new Date(dateBlock.final)
      const notInBlockWindow = !(start < date && date < final)
      const rule = getRuleById(location, rules)
      const timeRule = getTimeRule(date, rule)

      if (timeRule && dateToAmericanDate(date) === dateToAmericanDate(start)) {
        // verifica os horários vagos na data de início do período
        const availableTimes = checkBookingDateBlockPeriodTime(start, timeRule)
        return availableTimes.length > 0
      } else if (
        timeRule &&
        dateToAmericanDate(date) === dateToAmericanDate(final)
      ) {
        // verifica os horários vagos na data de fim do período
        const availableTimes = checkBookingDateBlockPeriodTime(
          final,
          timeRule,
          true
        )
        return availableTimes.length > 0
      }

      return notInBlockWindow
    })
    return inPeriod.every(i => i)
  }

  /**
   * filtro para remover horários fora do bloqueio por período
   * data do início do período - as horas do início do período
   * data do fim do período - as horas do fim do período
   */
  function timeFilterInBookingDateBlockPeriod(
    time: Date,
    selectedDate: Date,
    location: string,
    bookingDateBlocks: TBookingDateBlock[]
  ): boolean {
    const inPeriod: boolean[] = bookingDateBlocks.map(dateBlock => {
      // verifica localização
      const locationNumber = parseInt(location)
      const rule = getRuleById(location, rules)
      const timeRule = getTimeRule(selectedDate, rule)

      if (!dateBlock.locationId.includes(locationNumber)) return true

      // verifica data
      const start = new Date(dateBlock.start)
      const final = new Date(dateBlock.final)

      if (
        timeRule &&
        dateToAmericanDate(selectedDate) === dateToAmericanDate(start)
      ) {
        // verifica os horários vagos na data de início do período
        const availableTimes = checkBookingDateBlockPeriodTime(
          start,
          timeRule
        ).map(t => getFullTime(t))
        return availableTimes.includes(getFullTime(time))
      } else if (
        timeRule &&
        dateToAmericanDate(selectedDate) === dateToAmericanDate(final)
      ) {
        // verifica os horários vagos na data de fim do período
        const availableTimes = checkBookingDateBlockPeriodTime(
          final,
          timeRule,
          true
        ).map(t => getFullTime(t))
        return availableTimes.includes(getFullTime(time))
      }

      return true
    })
    return inPeriod.every(i => i)
  }

  /**
   * Algumas regras de validação de time
   * TODO separar essas regras para melhor manutenção
   */
  function timeValidation(
    fieldTime: Date,
    selectedDate: Date,
    ruleName: string
  ): boolean {
    if (!selectedDate) return false
    const day = selectDay(selectedDate.getDay())
    const rule = getRuleById(ruleName, rules)
    let timeRule = null
    if (!rule) return false
    if (Object.keys(rule).includes(day)) {
      timeRule = rule[day]
    } else if (rule.hollydays && isAHollyday(selectedDate)) {
      timeRule = rule.hollydays
    } else {
      timeRule = rule.default
    }
    if (
      !timeRule ||
      typeof timeRule === 'string' ||
      typeof timeRule === 'number'
    ) {
      return true
    }

    const openTime = newDateByTimeString(timeRule.minHour)
    const closeTime = newDateByTimeString(timeRule.maxHour)
    const dateTimeMerged = mergeDateDateAndTimeDate(selectedDate, fieldTime)
    const requiredDifTime = rule?.tempoAntecedencia

    return (
      inRequiredDiffBetweanDays(requiredDifTime, dateTimeMerged) &&
      timeIsBefore(openTime, fieldTime) &&
      timeIsBefore(fieldTime, closeTime) &&
      !isPastTime(fieldTime, selectedDate)
    )
  }

  return {
    dayTimeValidation,
    dayRuleIsNull,
    timeValidation,
    dateFilterInBookingDateBlockPeriod,
    timeFilterInBookingDateBlockPeriod
  }
}
